import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    ADMIN_LOCATIONS,
    ADMIN_LOCATIONS_CREATE,
} from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import LocationsList from 'Components/admin/locations/List';

export const TAB_LOCATIONS = 'locations';

export default class AdminLocations extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="page-locations"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_LOCATIONS.path),
                        label: 'Lokacje',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Lokacje"
                        controls={[{
                            visible: Boolean(!queryObject.tab || queryObject.tab === 'locations'),
                            key: 'createLocation',
                            label: 'Nowa lokacja',
                            onClick: () => history.push(ADMIN_LOCATIONS_CREATE.path),
                        }]}
                        tabs={[
                            {
                                key: TAB_LOCATIONS,
                                label: 'Lokacje',
                                children: (
                                    <LocationsList
                                        location={location}
                                        history={history}
                                    />
                                ),
                            },
                        ]}
                    />  
                </PageContent>
            </StyledComponent>
        );
    }
}
